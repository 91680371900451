<template>
  <BaseLayout
    v-bind="$attrs"
    :class="{
      'with-top-banner': isBannerShown,
    }"
  >
    <template v-slot:banner v-if="isBannerShown">
      <TopBanner />
    </template>
    <template v-slot:header-action>
      <region-search-autocomplete
        v-if="hasSearch"
        attach="#header-region-search-autocomplete"
        wrapper-id="header-region-search-autocomplete"
        class="header-region-search-autocomplete mr-3"
        v-model="regionSelectedInSearch"
        @input="goToRegion"
        @update:search-input="onUpdateSearchInput"
        @search="sendEventToCounters"
        :search-query="regionSearchQuery"
        :key="$route && $route.path"
        prepend-inner-icon="mdi-magnify"
        append-icon=""
        filled
        solo
        :outlined="false"
        flat
        clearable
        :placeholder="$t('app.searchLocation')"
      />
      <!--<template v-if="isDataLoaded">
        <template v-if="isAuthenticated">
          <v-avatar class="user-avatar cursor-pointer" size="40" id="app-userpic">
            <span class="subheading text-base">
              {{ initials }}
            </span>
          </v-avatar>
          <UserMenu activator="#app-userpic" offset-y has-external-profile nudge-bottom="4">
            <template v-slot:prepend>
              <div class="px-4">
                <div class="py-1 small">{{ caption }}</div>
              </div>
              <v-divider class="my-1"></v-divider>
            </template>
          </UserMenu>
        </template>
        <ng-button v-else href="/login" color="primary" depressed>{{ $t('app.signin') }}</ng-button>
      </template>-->
    </template>
    <slot></slot>
  </BaseLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { goToProductFromSearch } from '@/services/searchService';
import BaseLayout from '@ngservices_front/layouts/BaseLayout.vue';
import RegionSearchAutocomplete from '@/components/app/RegionSearchAutocomplete.vue';
import TopBanner from '@/components/app/TopBanner.vue';
// import UserMenu from '@ngservices_front/components/UserMenu/UserMenu.vue';

export default {
  components: {
    BaseLayout,
    RegionSearchAutocomplete,
    TopBanner,
    // UserMenu,
  },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      regionSelectedInSearch: undefined,
      regionSearchQuery: '',
    };
  },
  computed: {
    ...mapState('app', ['isBannerShown']),
    // ...mapState('user', ['isAuthenticated', 'isDataLoaded']),
    ...mapGetters('user', ['initials', 'caption']),
  },
  methods: {
    onUpdateSearchInput(newQuery) {
      this.regionSearchQuery = newQuery;
    },
    goToRegion() {
      if (this.regionSelectedInSearch) {
        goToProductFromSearch({
          regionFromSearch: this.regionSelectedInSearch,
          route: this.$route,
          router: this.$router,
        });

        this.$nextTick(() => {
          this.regionSelectedInSearch = undefined;
          this.regionSearchQuery = '';
        });
      }
    },
    sendEventToCounters(query) {
      if (window.ym) window.ym(42050389, 'reachGoal', 'app-search', { query });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-region-search-autocomplete {
  width: 200px;
  white-space: normal;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    width: 300px;
  }
}

.with-top-banner {
  :deep(.app-sidebar.v-navigation-drawer) {
    top: $header-height + $top-banner-height !important;
  }
}

.user-avatar {
  background: linear-gradient(180deg, #eacef7 0%, #cba6dc 100%), linear-gradient(#eacef7, #eacef7),
    linear-gradient(#ffd4d4, #ffd4d4);
}
</style>

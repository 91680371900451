import httpClient from '@/api/httpClient';
import userApi from '@/api/userApi';

const getUserVisitInfo = () => httpClient.get('api/visitinfo/').then((resp) => resp.data);

const loadUserDataToStore = async (store) => {
  try {
    const user = await userApi.getUser({
      options: { noErrorNotification: true },
    });
    store.dispatch('user/setUserData', user);
  } catch (error) {
    store.dispatch('user/setUserIsNotAuthenticated');
    if (error?.response?.status !== 403) throw error;
  }
};

export default { getUserVisitInfo, loadUserDataToStore };

const user = {
  namespaced: true,
  state: () => ({
    isAuthenticated: undefined,
    username: null,
    firstName: null,
    lastName: null,
    email: null,
    isDataLoaded: false,
  }),
  getters: {
    initials(state) {
      return state.firstName && state.lastName
        ? `${state.firstName.charAt(0)}${state.lastName.charAt(0)}`.toUpperCase()
        : state.username.charAt(0).toUpperCase();
    },
    caption(state) {
      return state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : state.username;
    },
  },
  mutations: {
    SET_USERNAME(state, username) {
      state.username = username;
    },
    SET_FIRST_NAME(state, firstName) {
      state.firstName = firstName;
    },
    SET_LAST_NAME(state, lastName) {
      state.lastName = lastName;
    },
    SET_EMAIL(state, email) {
      state.email = email;
    },
    SET_USER_IS_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_IS_DATA_LOADED(state, isDataLoaded) {
      state.isDataLoaded = isDataLoaded;
    },
  },
  actions: {
    setUserData({ commit }, userData) {
      commit('SET_USERNAME', userData.username);
      commit('SET_FIRST_NAME', userData.first_name);
      commit('SET_LAST_NAME', userData.last_name);
      commit('SET_EMAIL', userData.email);
      commit('SET_USER_IS_AUTHENTICATED', !!userData);
      commit('SET_IS_DATA_LOADED', true);
    },
    setUserIsNotAuthenticated({ commit }) {
      commit('SET_USER_IS_AUTHENTICATED', false);
      commit('SET_IS_DATA_LOADED', true);
    },
  },
};

export default user;

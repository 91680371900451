import Vue from 'vue';
import Vuex from 'vuex';
import baseApp from '@ngservices_front/store/modules/baseApp';
import baseUser from '@ngservices_front/store/modules/baseUser';
import app from './modules/app';
import catalog from './modules/catalog';
import regions from './modules/regions';
import product from './modules/product';
import order from './modules/order';
import user from './modules/user';
import promotions from './modules/promotions';
import map from './modules/map';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    user,
    catalog,
    regions,
    product,
    order,
    promotions,
    map,
    // common modules
    baseApp,
    baseUser,
  },
  strict: debug,
});

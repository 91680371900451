<template>
  <div class="ng-fieldset">
    <slot name="prepend"></slot>
    <v-row class="my-0">
      <template v-for="(field, key) in fields">
        <v-col
          class="ng-fieldset__col py-0"
          :key="key"
          cols="12"
          :sm="field.col || 12"
        >
          <slot
            v-if="hasSlot(key)"
            :name="'field.' + key"
            :field="field"
            :formValue="localValue"
          >
          </slot>
          <template v-else>
            <div
              v-if="field.prependText"
              v-html="field.prependText.content"
              v-bind="field.prependText.attrs"
            ></div>
            <ng-text-field
              class="mb-4"
              v-if="field.widget === 'text'"
              :label="field.label"
              :name="field.name"
              :placeholder="field.placeholder"
              v-model.trim="localValue[key]"
              :error="!!field.errors.length || !!field.serverErrors.length"
              :error-count="field.serverErrors.length || field.errors.length"
              :error-messages="field.serverErrors || field.errors"
              @input="onFieldInput(field, value[key])"
              v-bind="field.attrs"
            ></ng-text-field>
            <ng-password-text-field
              class="mb-4"
              v-if="field.widget === 'password'"
              :label="field.label"
              :name="field.name"
              :placeholder="field.placeholder"
              v-model.trim="localValue[key]"
              :error="!!field.errors.length || !!field.serverErrors.length"
              :error-count="field.serverErrors.length || field.errors.length"
              :error-messages="field.serverErrors || field.errors"
              @input="onFieldInput(field, value[key])"
              v-bind="field.attrs"
            ></ng-password-text-field>
            <ng-select
              class="mb-4"
              v-if="field.widget === 'select'"
              :label="field.label"
              :name="field.name"
              :placeholder="field.placeholder"
              :items="field.options"
              v-model.trim="localValue[key]"
              :error="!!field.errors.length || !!field.serverErrors.length"
              :error-count="field.serverErrors.length || field.errors.length"
              :error-messages="field.serverErrors || field.errors"
              @input="onFieldInput(field, value[key])"
              v-bind="field.attrs"
            ></ng-select>
            <ng-phone-input
              class="mb-4"
              v-if="field.widget === 'phone'"
              :label="field.label"
              :name="field.name"
              :placeholder="field.placeholder"
              v-model.trim="localValue[key]"
              :error="!!field.errors.length || !!field.serverErrors.length"
              :error-count="field.serverErrors.length || field.errors.length"
              :error-messages="field.serverErrors || field.errors"
              @input="onFieldInput(field, value[key])"
              v-bind="field.attrs"
            ></ng-phone-input>
            <ng-autocomplete
              class="mb-4"
              v-if="field.widget === 'autocomplete'"
              :label="field.label"
              :name="field.name"
              :placeholder="field.placeholder"
              :items="field.options"
              v-model.trim="localValue[key]"
              :error="!!field.errors.length || !!field.serverErrors.length"
              :error-count="field.serverErrors.length || field.errors.length"
              :error-messages="field.serverErrors || field.errors"
              @input="onFieldInput(field, value[key])"
              v-bind="field.attrs"
            ></ng-autocomplete>
            <ng-text-area
              class="mb-4"
              v-if="field.widget === 'textarea'"
              :label="field.label"
              :name="field.name"
              :placeholder="field.placeholder"
              v-model.trim="localValue[key]"
              :error="!!field.errors.length || !!field.serverErrors.length"
              :error-count="field.serverErrors.length || field.errors.length"
              :error-messages="field.serverErrors || field.errors"
              @input="onFieldInput(field, value[key])"
              v-bind="field.attrs"
            ></ng-text-area>
            <ng-checkbox
              class="mb-4"
              v-if="field.widget === 'checkbox'"
              :label="field.label"
              :name="field.name"
              :id="field.id"
              v-model="localValue[key]"
              :error="!!field.errors.length || !!field.serverErrors.length"
              :error-count="field.serverErrors.length || field.errors.length"
              :error-messages="field.serverErrors || field.errors"
              @input="onFieldInput(field, value[key])"
              v-bind="field.attrs"
              @keydown="field.listeners.keydown"
            ></ng-checkbox>
            <v-btn-toggle
              class="ng-fieldset__toggle mb-4"
              v-if="field.widget === 'toggle'"
              v-model="localValue[key]"
              v-bind="field.attrs"
              @input="onFieldInput(field, value[key])"
            >
              <v-btn
                class="px-2"
                v-for="(option, index) in field.options"
                :key="index"
                text
                :value="option.value"
                v-html="option.text"
              >
              </v-btn>
            </v-btn-toggle>
            <ng-date-picker
              class="mb-4"
              v-if="field.widget === 'datepicker'"
              v-bind="field.attrs.datepicker"
              v-model="localValue[key]"
              :label="field.label"
              :placeholder="field.placeholder"
              :formatFunc="field.formatFunc"
              :menuProps="field.attrs.menu"
              :textfieldProps="field.attrs.text"
            />
            <ng-card-group
              class="mb-4"
              v-if="field.widget === 'cardGroup'"
              :label="field.label"
              :items="field.options"
              v-bind="field.attrs"
              v-model="localValue[key]"
              :error="!!field.errors.length || !!field.serverErrors.length"
              :error-messages="field.serverErrors || field.errors"
            ></ng-card-group>
            <v-tooltip
              v-if="field.help"
              class="ng-fieldset__help cursor-pointer"
              v-bind="field.help.attrs"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ng-fieldset__help-icon" v-bind="attrs" v-on="on"
                  >mdi-help-circle</v-icon
                >
              </template>
              <span v-html="field.help.text"></span>
            </v-tooltip>
            <div
              class="body-2 mt-n3 mb-4 text-muted"
              v-if="field.hintOutside"
              v-bind="field.hintOutside.attrs"
              v-html="field.hintOutside.text"
            ></div>
          </template>
        </v-col>
      </template>
    </v-row>
    <slot name="append"></slot>
  </div>
</template>

<script>
export default {
  name: 'NgFieldset',
  props: {
    fields: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localValue() {
      return this.value;
    },
  },
  watch: {
    localValue: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
  },
  methods: {
    hasSlot(fieldKey) {
      const name = `field.${String(fieldKey)}`;
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    onFieldInput(field) {
      // eslint-disable-next-line no-param-reassign
      field.serverErrors = '';
    },
  },
};
</script>

<style lang="scss">
.ng-fieldset {
  &__col {
    position: relative;
  }

  .ng-fieldset__help-icon {
    font-size: 18px;
    position: absolute;
    top: 50%;
    right: -12px;
    margin-top: -17px; //fontSize/2 + mb-4/2
    z-index: 5;
    cursor: pointer;
  }

  &__toggle {
    .v-btn {
      font-size: 16px;
    }
  }
}
</style>

<template>
  <!-- @keydown="onKeydown" is handled to provide @keydown event in parent component. Vuetify2 v-checkbox is not focusable.
   This issue is solved in Vuetify 3. After moving therer @keydown="onKeydown" should be deleted -->
  <div class="ng-checkbox" tabindex="0" @keydown="onKeydown">
    <v-checkbox v-model="$attrs.value" v-bind="innerAttrs" @change="onChange()">
      <template v-slot:label v-if="innerAttrs.label">
        <span ref="label" v-html="$attrs.label"></span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'NgCheckbox',
  data() {
    return {
      defaultSettings: {
        hideDetails: 'auto',
      },
    };
  },
  computed: {
    innerAttrs() {
      return {
        ...this.defaultSettings,
        ...this.$attrs,
      };
    },
  },
  mounted() {
    this.initInnerLinks();
  },
  methods: {
    initInnerLinks() {
      const innerLinks = this.$refs.label.querySelectorAll('a');
      [...innerLinks].forEach((link) => {
        link.addEventListener('click', (e) => {
          e.stopPropagation();
        });
      });
    },
    onChange() {
      this.$emit('input', this.$attrs.value);
    },
    onKeydown(event) {
      this.$emit('keydown', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.ng-checkbox {
  &::v-deep .v-input__slot {
    align-items: stretch;
  }

  &::v-deep .v-input--selection-controls {
    margin-top: 0;
  }

  &::v-deep .error--text a {
    color: currentColor;
    border-bottom-color: currentColor;
  }

  :deep(label) {
    margin-bottom: 0;
    color: currentColor;
  }
}
</style>

<template>
  <component :is="layoutComponent" v-bind="layoutComponentProps">
    <router-view></router-view>
  </component>
</template>

<script>
import { debounce } from 'lodash-es';
// import { notice } from '@nextgis/vuetify-notice';
import config from '@/config';
import userService from '@/services/userService';
import chatraService from '@/services/chatraService';
// import { convertAxiosErrorToAppError } from '@/utils/errorUtils';

export default {
  data() {
    return {};
  },
  computed: {
    layoutComponent() {
      return (
        (this.$route.meta.layout && this.$route.meta.layout.component) || {
          render() {
            return this.$slots.default;
          },
        }
      );
    },
    layoutComponentProps() {
      return this.$route.meta.layout && this.$route.meta.layout.props;
    },
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.$store.dispatch('baseApp/init', config({ i18n: this.$i18n }));
        this.setChatra();
      },
      immediate: true,
    },
  },
  async beforeCreate() {
    try {
      const { from_country } = await userService.getUserVisitInfo();
      this.$store.commit('baseUser/SET_USER_IP_COUNTRY', from_country);
    } catch (e) {
      this.$store.commit('baseUser/SET_USER_IP_COUNTRY', null);
      console.error(e);
    }
  },
  async created() {
    // try {
    //   await userService.loadUserDataToStore(this.$store);
    // } catch (error) {
    //   const { errorMessage } = convertAxiosErrorToAppError({
    //     axiosError: error,
    //     locale: 'en',
    //   });
    //   notice(errorMessage, {
    //     color: 'error',
    //     timeout: 5000,
    //     icon: 'error',
    //   });
    // }
  },
  methods: {
    // eslint-disable-next-line func-names
    setChatra: debounce(function () {
      if (chatraService.isEnabled()) {
        chatraService.updateLanguage(this.$i18n.locale);
      } else {
        chatraService.init(this.$i18n.locale);
      }
    }, 1000),
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-menu class="user-menu" v-bind="$attrs" v-on="$listeners">
    <ng-list :items="menuItems" dense class="mx-n1">
      <template v-slot:prepend>
        <slot name="prepend"></slot>
      </template>
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
    </ng-list>
  </v-menu>
</template>

<i18n>
  {
    "en": {
      "profile": "Profile",
      "logout": "Sign out"
    },
    "ru": {
      "profile": "Профиль",
      "logout": "Выйти"
    },
    "es": {
      "profile": "Perfil",
      "logout": "Cerrar sesión"
    },
    "fr": {
      "profile": "Profil",
      "logout": "Se déconnecter"
    }
  }
</i18n>

<script>
import NgList from '../ui/NgList/NgList.vue';
import { getUrlByLocale } from '../../services/UrlService';

export default {
  name: 'UserMenu',
  components: { NgList },
  props: {
    logoutUrl: {
      type: String,
    },
    profileUrl: {
      type: String,
    },
    hasExternalProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuItems: [
        {
          iconPrepend: 'mdi-account-box',
          title: this.$t('profile'),
          href:
            this.profileUrl ||
            getUrlByLocale('ngid_profile', this.$i18n.locale),
          target: this.hasExternalProfile ? '_blank' : '_self',
        },
        {
          iconPrepend: 'mdi-exit-to-app',
          title: this.$t('logout'),
          href: this.logoutUrl || getUrlByLocale('logout', this.$i18n.locale),
          // iconAppend: 'mdi-exit-to-app',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
